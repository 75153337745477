<template>
  <div class="home__lives d-flex bg-white p-0" :class="isSideBar ? '' : 'col-sm-5 ml-0 ml-xl-3 mt-3 mt-lg-0'">
    <Loading v-if="loading" class="tlw-h-96" />
    <div v-else class="home__lives__bg w-100" :class="isSideBar ? '--sideBar' : 'p-4'">
      <b-img v-if="isSideBar" class="w-100" :src="require('@/assets/images/home/sidebar-live-meets.svg')" />
      <div :class="{ 'p-4': isSideBar }">
        <b-carousel
          class="col-sm-10 border-0 pl-0"
          :class="{ 'mw-100 p-0': isSideBar }"
          id="carousel-lives"
          v-model="lives"
          no-wrap
          :indicators="liveMeets.length > 1"
          :controls="liveMeets.length > 1"
          :interval="0"
        >
          <b-carousel-slide class="live-content" v-for="(live, index) in liveMeets" :key="index">
            <template #img>
              <div class="d-flex flex-column pr-2">
                <h5 class="font-weight-bold mb-3">
                  {{ $t(`${prefixLang}.slide-title`) }}
                </h5>
                <span class="tlw-block tlw-text-sm tlw-mb-6 text-truncate">{{ live.title }}</span>
                <MButton
                  :class="liveMeets.length > 1 ? '' : 'mt-5'"
                  :label="$t(`${prefixLang}.btn-access-transmission`)"
                  variant="primary"
                  @click="acessTransmission(live)"
                />
                <MButton
                  class="btn-check-schedule p-0 mt-3"
                  :label="$t(`${prefixLang}.btn-access-schedule`)"
                  variant="link"
                  @click="accessSchedule(live)"
                />
              </div>
            </template>
          </b-carousel-slide>
        </b-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import MButton from '@/shared/components/MButton.vue';
import Loading from '@/components/Loading.vue';

export default {
  components: {
    Loading,
    MButton,
  },
  props: {
    isSideBar: {
      required: true,
      type: Boolean,
    },
    loading: {
      required: true,
      type: Boolean,
    },
    liveMeets: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      lives: 0,
      prefixLang: 'home-page.live-meets.component',
    };
  },
  methods: {
    acessTransmission(live) {
      let route = this.$router.resolve({ name: 'LiveMeetRoom', params: { liveMeetId: live.id } });
      window.open(route.href, '_blank');
    },
    accessSchedule(live) {
      this.$router.push({
        name: 'ContentLives',
        params: { id: live.courses[0].school_product_ids[0], courseId: live.courses[0].id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.home__lives {
  border-radius: 4px;
  /deep/ .carousel {
    height: calc(100% - 30px);
    .live-content {
      min-height: 130px;
    }

    .carousel-control-next,
    .carousel-control-prev {
      width: 32px;
      height: 32px;
      opacity: 1;
      top: inherit;
      bottom: -2rem;
      background: #7427f1;
      border-radius: 4px;
      transition: background 0.5s;

      &:hover {
        background-color: var(--color-purple-800);
      }

      &-icon {
        width: 14px;
      }
    }

    .carousel-inner {
      min-height: 100%;
    }

    .carousel-indicators {
      position: relative;
      top: 1.36rem;
      left: 0.4rem;

      li {
        width: 8px;
        height: 8px;
        border: 0;
        background: #7427f1;
        border-radius: 50%;
      }
    }
  }
}
/deep/ .btn-check-schedule {
  &:hover {
    span {
      text-decoration: underline !important;
    }
  }
}
.home__lives__bg:not(.--sideBar) {
  background-image: url('~@/assets/images/home/horizontal-live-meets.svg');
  background-repeat: no-repeat;
  background-position: center right;
}
.home__lives__bg {
  &.--sideBar {
    /deep/ .carousel {
      .live-content {
        min-height: 259px;
        h5 {
          font-size: 1.25rem;
        }
      }
      .carousel-control-next,
      .carousel-control-prev {
        bottom: -1rem;
      }
      .carousel-indicators {
        top: 0.2rem;
        left: 0rem;
      }
    }
  }
}
@media screen and (max-width: $screen-lg) {
  .home__lives {
    max-width: 550px;
  }
}
</style>
