
































import { Component, Vue, Prop } from 'vue-property-decorator';
import TrackingHelper from '@/shared/helpers/tracking';

@Component({})
export default class HomeFunnelCreate extends Vue {
  @Prop() hasFunnel: boolean;

  trackFunnelCreating() {
    TrackingHelper.trackFunnelCreationInitiated('home_page');
  }
}
