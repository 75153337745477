<template>
  <div class="dash-info">
    <CardMoney />
    <CardDashboardInfo
      v-for="card in cards"
      :key="card.id"
      :value="String(card.value)"
      :icon="card.icon"
      :title="card.title"
      :routeName="card.link.name"
      :routeTitle="card.link.title"
      :event="card.event"
      :tooltip="card.tooltip"
    />
  </div>
</template>

<script>
import CardDashboardInfo from './CardDashboardInfo.vue';
import CardMoney from './CardMoney.vue';
import { mapState } from 'vuex';

export default {
  name: 'Info',
  components: {
    CardDashboardInfo,
    CardMoney,
  },
  computed: {
    ...mapState('home', {
      activeLeadsCount: state => state.active_leads_count,
      activeStudentsCount: state => state.active_students_count,
    }),
    cards() {
      return [
        {
          id: 1,
          icon: {
            name: 'users-class',
            color: 'blue',
          },
          title: 'Total de membros',
          value: this.activeStudentsCount,
          tooltip: 'O número total de membros é referente a todos os seus produtos.',
          link: { name: 'Members', title: 'Página de Membros' },
        },
        {
          id: 2,
          icon: {
            name: 'users',
            color: 'purple',
          },
          title: 'Leads capturados',
          value: this.activeLeadsCount,
          link: { name: 'LeadsManager', title: 'Página de Leads' },
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.dash-info {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;

  width: 100%;
  height: auto;
}

@media screen and (max-width: 768px) {
  .dash-info {
    grid-template-columns: 1fr;
  }
}
</style>
