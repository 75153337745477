<template>
  <b-modal
    size="md"
    centered
    hideFooter
    scrollable
    visible
    no-close-on-esc
    body-class="tlw-text-center"
    header-class="pb-0 border-bottom-0"
    :id="modalId"
    @hidden="$emit('hidden-modal')"
  >
    <div class="d-flex flex-column align-items-center">
      <b-img-lazy height="170" width="170" :src="require('@/assets/images/coin-megaphone.svg')" />
      <h3 class="mt-4 mb-3 font-weight-bold tlw-px-4">Solicitação de saque durante Natal e o Ano novo</h3>
      <p class="description">
        Devido às festividades de fim de ano, nos dias
        <span class="text-purple">24 e 31 de dezembro não haverá operação</span>. Todos os
        <span class="text-purple">saques pendentes</span> serão processados no
        <span class="text-purple">próximo dia útil</span> seguindo o nosso SLA.
      </p>
      <p class="description nowrap">Agradecemos a compreensão e desejamos boas festas! 🎉</p>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    modalId: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .modal-content {
  .modal-body {
    padding: 0 32px 32px 32px;
  }
  .close {
    font-size: 1.875rem;
  }
  h3 {
    font-size: 24px;
  }
  .description {
    font-size: 16px;
    line-height: 24px;
    span {
      font-weight: 700;
    }
  }
}

@media screen and (min-width: 768px) {
  /deep/ .modal-content {
    .nowrap {
      white-space: nowrap;
    }
  }
}
</style>
