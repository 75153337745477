<template>
  <div class="progress-circle">
    <svg :height="size" :width="size" :viewBox="`0 0 ${size} ${size}`">
      <defs>
        <linearGradient id="progressGradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" :stop-color="gradientColors.start" />
          <stop offset="100%" :stop-color="gradientColors.end" />
        </linearGradient>
      </defs>
      <circle
        :cx="center"
        :cy="center"
        :r="radius"
        stroke="url(#progressGradient)"
        :stroke-width="strokeWidth"
        opacity="0.4"
        fill="none"
      />
      <circle
        :cx="center"
        :cy="center"
        :r="radius"
        stroke="url(#progressGradient)"
        :stroke-width="strokeWidth"
        fill="none"
        :stroke-dasharray="circumference"
        :stroke-dashoffset="strokeDashoffset"
        stroke-linecap="round"
      />
    </svg>
    <span class="progress-text">{{ progress }}%</span>
  </div>
</template>

<script>
const JOURNEY_LEVEL_GRADIENT_COLORS = {
  brave: {
    start: '#683905',
    end: '#F15C24',
  },
  hero: {
    start: '#08A102',
    end: '#05D958',
  },
  super_hero: {
    start: '#0052D3',
    end: '#00D9FC',
  },
  black: {
    start: '#000000',
    end: '#383E40',
  },
  golden: {
    start: '#DAB500',
    end: '#C78F20',
  },
  keep_working: {
    start: '#DADADA',
    end: '#999999',
  },
};

export default {
  name: 'ProgressCircle',
  props: {
    progress: {
      type: Number,
      required: true,
      validator: value => value >= 0 && value <= 100,
    },
    size: {
      type: Number,
      default: 64,
    },
    journeyStage: {
      type: String,
      default: 'keep_working',
    },
  },
  data() {
    return {
      strokeWidth: 4,
    };
  },
  computed: {
    radius() {
      return this.size / 2 - this.strokeWidth;
    },
    center() {
      return this.size / 2;
    },
    circumference() {
      return 2 * Math.PI * this.radius;
    },
    strokeDashoffset() {
      return this.circumference - (this.progress / 100) * this.circumference;
    },
    gradientColors() {
      return JOURNEY_LEVEL_GRADIENT_COLORS[this.journeyStage] || JOURNEY_LEVEL_GRADIENT_COLORS.keep_working;
    },
  },
};
</script>

<style scoped>
.progress-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.progress-text {
  position: absolute;
  font-size: 16px;
  font-weight: 700;
  color: #262626;
}
</style>
