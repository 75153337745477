
























import TrackingHelper from '@/shared/helpers/tracking';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    CreateProductButton: () => import('@/components/CreateProductButton.vue'),
  },
})
export default class HomeBanner extends Vue {
  location = 'home_page';
  offer_name = 'Home_Banner';
  email: string = this.$store.state.school.selectedSchool.owner.email;
  school_id: any = this.$store.state.school.selectedSchool.id;

  get canCreateProduct() {
    return this.$ACL.canCreateProduct();
  }

  get BannerData() {
    return {
      src: require('@/assets/images/home/rocket.svg'),
      alt: 'rocket',
      class: 'home__banner-logo',
    };
  }

  trackBannerLinkClicked() {
    TrackingHelper.trackBannerLinkClicked(this.location, this.offer_name, this.email, this.school_id);
  }

  mounted() {
    TrackingHelper.trackBannerViewed(this.location, this.offer_name, this.email, this.school_id);
  }
}
