export default () => [
  {
    title:
      'Já fatura <span class="gradient">mais de 50k?</span> Temos <br />uma <span class="gradient-2">oferta especial</span> para você!',
    subtitle: '<span class="black">Taxa justa</span> e checkout de alta performance',
    itens: [
      'Nós migramos tudo para você',
      'Sem fidelidade',
      'Gerente de contas dedicado',
      'Área de membros Hero ou Externa',
      'Recebimento em D+2',
      'Checkout de alta performance (+93% de conversão)',
    ],
    btn_label: 'Quero falar com um especialista',
  },
  {
    title: 'Turbine as <span class="gradient">suas vendas</span> com as extensões HeroSpark',
    subtitle: '<span class="black">50% de desconto</span> na assinatura <b>anual</b>',
    itens: [
      'Domínio customizado',
      'Páginas ilimitadas',
      'E-mail ilimitados',
      'Hospedagem gratuita',
      'Checkout de alta conversão',
      'Área de membros com vídeos próprios',
    ],
    btn_label: 'Anual 50% OFF: 12xR$97',
    price: 'ou R$ 197,00 por mês',
  },
];
