<template>
  <div class="card-dashboard">
    <div class="card-dashboard__left" v-if="!isLoading">
      <div class="card-dashboard-icon">
        <i :class="`fal fa-${icon.name} ${icon.color}`" />
      </div>

      <div class="card-dashboard-content">
        <p>{{ title }}</p>
        <div class="card-dashboard-content__money tlw-flex tlw-gap-3">
          <strong v-if="canSeeMoney">{{ value }}</strong>
          <div v-else></div>
          <button @click="tttt">
            <hs-icon class="icon-color-wallet" variant="light" :icon="`eye${canSeeMoney ? '' : '-slash'}`" />
          </button>
        </div>
        <a class="tlw-font-bold" @click="handleLinkNavigation">{{ link.title }}</a>
      </div>
    </div>

    <div class="card-dashboard__left" v-else>
      <div class="card-dashboard-icon">
        <hs-placeholder animated width="56px" height="56px" />
      </div>

      <div class="card-dashboard-content">
        <hs-placeholder animated width="154px" height="20px" class="mb-1" />
        <hs-placeholder animated width="154px" height="40px" class="mb-1" />
        <hs-placeholder animated width="154px" height="18px" />
      </div>
    </div>

    <div class="card-dashboard__right" v-if="tooltip">
      <hs-icon variant="light" icon="info-circle" v-if="!isLoading" v-b-tooltip.hover.top="tooltip" />
      <hs-placeholder animated width="24px" height="24px" v-else />
    </div>
  </div>
</template>

<script>
import GeneralHelper from '@/shared/helpers/general';
import { analyticsService } from '@/services';
import ToastHelper from '@/shared/helpers/toast';
import { mapState, mapActions } from 'vuex';
import { getTransactionSummary } from '@/services/jarvis';

export default {
  name: 'CardDashboardInfo',
  data() {
    return {
      isLoading: false,
      id: 1,
      icon: {
        name: 'money-bill-alt',
        color: 'green',
      },
      title: 'Valor líquido de vendas',
      value: 0,
      link: { name: 'SalesMadeReport', title: 'Relatório de Vendas' },
      tooltip: 'Valor total das suas vendas, descontando taxas e comissões de afiliados e coprodutores, se houver.',
      event: {
        name: 'Accessed report',
        props: {
          location: 'home',
          report: 'SalesMadeReport',
        },
      },
    };
  },
  async mounted() {
    try {
      this.isLoading = true;

      const canSeeMoney = JSON.parse(localStorage.getItem('canSeeMoney'));
      this.setCanSeeMoney(canSeeMoney);

      const response = await getTransactionSummary();

      this.value = GeneralHelper.currency(response?.data?.comission || 0);
    } catch (error) {
      ToastHelper.dangerMessage('Erro ao buscar informações do dashboard');
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions('home', ['setCanSeeMoney']),
    tttt() {
      this.setCanSeeMoney(!this.canSeeMoney);
    },
    handleLinkNavigation() {
      analyticsService.track({
        event: this.event.name,
        props: { ...this.event.props },
      });

      this.$router.push({ name: this.link.name });
    },
  },
  computed: {
    ...mapState('home', {
      canSeeMoney: state => state.canSeeMoney,
    }),
  },
};
</script>

<style lang="scss" scoped>
.card-dashboard {
  display: flex;
  justify-content: space-between;
  background-color: $white;
  padding: 24px;
  border-radius: 4px;

  &__right {
    color: #418cfc;
  }

  &__left {
    display: flex;
    align-self: start;
    gap: 12px;
  }

  &-icon {
    i {
      height: 56px;
      width: 56px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;

      &.green {
        color: #112b11;
        background-color: #e3fab8;
      }

      &.purple {
        color: #7427f1;
        background-color: #ead3fe;
      }

      &.blue {
        color: #2050b5;
        background-color: #b3dafe;
      }
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__money {
      > div {
        margin-top: 4.8px;
        margin-bottom: 10px;
        width: 116px;
        border-radius: 99px;
        height: 20px;
        background-color: #e3e3e3;
      }

      button {
        outline: 0;
      }
    }

    p {
      font-size: 14px;
      margin: 0;
      line-height: 145%;
      color: #3f3f3f;
    }

    strong {
      font-size: 24px;
      line-height: 145%;
      color: #3f3f3f;
    }

    a {
      cursor: pointer;
      line-height: 125%;
      font-size: 14px;
      color: #4213ad;
      text-decoration: underline;
    }
  }
}
</style>
