<template>
  <div v-if="isReady" class="home__banner tlw-w-full tlw-bg-no-repeat tlw-p-5" :class="{ 'with-team': isWithTeam }">
    <div class="home__banner-content tlw-w-full md:tlw-w-2/4 h-100 ">
      <div class="home__banner-content__container tlw-w-full h-100 d-flex flex-column justify-content-around">
        <h2 class="home__banner-title tlw-font-extrabold" v-html="promotionalData.title"></h2>
        <p class="home__banner-descount font-size-xs" v-html="promotionalData.subtitle"></p>
        <div class="home__banner-pills tlw-mt-5">
          <hs-badge v-for="(item, index) in promotionalData.itens" :key="index" pill class="py-1 px-2">
            {{ item }}
          </hs-badge>
        </div>
        <div class="home__banner-action text-center">
          <MButton
            :label="promotionalData.btn_label"
            variant="primary"
            size="md"
            @click="openCheckout"
            class="tlw-mt-5 tlw-py-6 px-4 w-100"
          />
          <p class="tlw-mt-2 font-size-xs mb-0">{{ promotionalData.price }}</p>
        </div>
      </div>
    </div>
    <HomePromotionalModal />
  </div>
</template>
<script>
import MButton from '@/shared/components/MButton.vue';
import HomePromotionalModal from './HomePromotionalModal.vue';
import PromotionalBannerInformation from '@/data/promotional_banner_information.js';
import dayjs from 'dayjs';

export default {
  components: {
    MButton,
    HomePromotionalModal,
  },
  props: {
    teamSize: {
      type: String,
      default: '',
    },
    createdAt: {
      type: String,
      default: '',
    },
    plan: {
      type: String,
      default: '',
    },
    plataforms: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      url: 'https://herospark.com/academia/extensao-plano-basic/',
      isReady: false,
    };
  },
  computed: {
    isWithTeam() {
      return (this.teamSize === 'withTeam' || this.plataforms.length > 0) && this.isCreatedAfterTheDate && this.isFree;
    },
    isCreatedAfterTheDate() {
      return dayjs('2024-01-31').diff(dayjs(this.createdAt)) <= 0;
    },
    isFree() {
      return this.plan === 'free';
    },
    promotionalData() {
      return this.isWithTeam ? PromotionalBannerInformation()[0] : PromotionalBannerInformation()[1];
    },
  },
  mounted() {
    this.isReady = true;
  },
  methods: {
    openCheckout() {
      if (this.isWithTeam) {
        this.$bvModal.show('home-promotional-modal');
      } else {
        window.open(this.url, '_blank');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  &__banner {
    min-height: 314px;
    display: flex;
    align-items: center;
    background-image: url('~@/assets/images/home/home-promotional-banner-bg.png');
    background-size: contain;
    background-position: right;
    background-color: #fff;
    border-radius: 4px;
    color: #000;
    /deep/ &-title {
      font-size: 24px;
      .gradient,
      .gradient-2 {
        background: linear-gradient(90deg, #ff305c 0%, #7427f1 49.48%, #08b2c7 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    &-content {
      &__container {
        width: fit-content;
      }
    }
    /deep/ &-descount {
      .black {
        color: #fff;
        background: #000;
      }
    }
    &-pills {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 12px;
      max-width: 430px;
      .badge-pill {
        width: fit-content;
        font-weight: 600;
        background: linear-gradient(white, white) padding-box,
          linear-gradient(90deg, #ff305c 0%, #7427f1 49.48%, #08b2c7 100%) border-box;
        border-radius: 50em;
        border: 1px solid transparent;
        color: #262626;
      }
    }
    &-action {
      width: fit-content;
    }
  }
  &__banner.with-team {
    background-image: url('~@/assets/images/home/home-promotional-team-banner-bg.png');
    /deep/ .gradient {
      background: linear-gradient(90deg, #ff305c 42.95%, #7427f1 75.34%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    /deep/ .gradient-2 {
      background: linear-gradient(90deg, #ff305c 62.95%, #7427f1 95.34%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    /deep/ .badge-pill {
      background: linear-gradient(white, white) padding-box,
        linear-gradient(90deg, #08b2c7 0%, #5c46e7 49.48%, #fc3561 100%) border-box;
    }
  }
}

@media only screen and (max-width: $screen-md) {
  .home {
    &__banner {
      background-image: none;
      text-align: left;
      &-pills {
        display: flex;
        flex-direction: column;
      }
      &-action {
        width: 100%;
      }
    }
    &__banner.with-team {
      background-image: none;
    }
  }
}
</style>
