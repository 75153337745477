<template>
  <div class="home__card d-flex flex-column flex-md-row align-items-center px-4 py-3">
    <img :width="bannerSize.width" :height="bannerSize.height" :src="bannerImage" alt="red-padlock" />
    <div class="ml-3 mt-3 mt-md-0">
      <h4 class="validation-title text-center text-md-left">{{ $t('home-page.phone_validation.title') }}</h4>
      <MButton
        @click="$router.push({ name: 'MyAccountSettings' })"
        class="validation-link p-0 mt-3 mt-md-0"
        :label="$t('home-page.phone_validation.btn')"
        variant="link"
        size="md"
      />
    </div>
  </div>
</template>

<script>
import MButton from '@/shared/components/MButton.vue';
import BrowserHelper from '@/shared/helpers/browser';

export default {
  components: {
    MButton,
  },
  data() {
    return {
      bannerImage: require(`@/assets/images/myspark/red-padlock.svg`),
      bannerSize: {
        width: BrowserHelper.isMobile() ? 104 : 30,
        height: BrowserHelper.isMobile() ? 104 : 45,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.validation-title {
  font-weight: 600;
}
.validation-link {
  text-decoration: underline !important;
}
</style>
