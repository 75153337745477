import { mapState } from 'vuex';

const writeKey = process.env.VUE_APP_SATISMETER_WRITE_KEY;

export default {
  computed: {
    ...mapState('auth', {
      loggedUser: state => state.loggedUser,
    }),
  },
  methods: {
    injectSatisMeter() {
      if (window.satismeter) return;

      (function() {
        window.satismeter =
          window.satismeter ||
          function() {
            (window.satismeter.q = window.satismeter.q || []).push(arguments);
          };
        window.satismeter.l = 1 * new Date();
        var script = document.createElement('script');
        var parent = document.getElementsByTagName('script')[0].parentNode;
        script.async = 1;
        script.src = 'https://app.satismeter.com/js';
        parent.appendChild(script);
      })();

      satismeter({
        writeKey,
        userId: this.loggedUser.id,
        traits: {
          name: this.loggedUser.full_name,
          email: this.loggedUser.email,
        },
      });
    },
  },
  mounted() {
    this.injectSatisMeter();
  },
};
