<template>
  <div class="home-greetings">
    <div class="home-greetings__info-wrapper">
      <h2 class="home-greetings__welcome-title" data-testid="welcome-title">
        {{ $t('home-page.greetings.title') }} {{ userName }}
      </h2>

      <div class="home-greetings__business-info-wrapper">
        <div class="home-greetings__business-info-item">
          <div class="home-greetings__icon-wrapper">
            <Icon size="xs" name="link" prefix="fal" />
          </div>

          <div class="home-greetings__business-url">
            {{ $t('home-page.greetings.url') }}
            <a
              :href="getDomainWithHttp"
              target="_blank"
              rel="noopener noreferrer"
              class="home-greetings__business-url-link"
              data-testid="business-url"
            >
              {{ getDomainWithHttp }}
            </a>
          </div>
        </div>

        <div class="home-greetings__business-info-item">
          <div class="home-greetings__icon-wrapper">
            <Icon size="xs" name="envelope" prefix="fal" />
          </div>

          <div class="home-greetings__business-url">
            {{ $t('home-page.greetings.email') }}
            <p class="home-greetings__business-email" data-testid="business-email">
              {{ selectedSchool.email }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <JourneyCard />
  </div>
</template>

<script>
import Icon from '@/components-new/Icon.vue';
import JourneyCard from '@/components/JourneyCard/index.vue';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'HomeGreetings',
  components: {
    JourneyCard,
    Icon,
  },
  computed: {
    ...mapGetters('school', ['getDomainWithHttp']),
    ...mapGetters('auth', {
      userName: 'name',
    }),
    ...mapState('school', ['selectedSchool']),
  },
};
</script>

<style lang="scss" scoped>
.home-greetings {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  background-color: $white;
  border-radius: 4px;
  padding: 20px 16px;

  @media (min-width: 768px) {
    padding: 24px;
    grid-template-columns: 1fr 335px;
  }
}

.home-greetings__info-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.home-greetings__welcome-title {
  font-size: 20px;
  font-weight: 600;
  color: black;
  line-height: 25px;
  margin: 0;
}

.home-greetings__business-info-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 72px;
  }
}

.home-greetings__business-info-item {
  display: flex;
  column-gap: 12px;
}

.home-greetings__business-url {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: black;
}

.home-greetings__icon-wrapper {
  min-height: 40px;
  min-width: 40px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #d9eefe;
}

.home-greetings__business-url-link {
  color: #4213ad;
  text-decoration: underline;
}
</style>
