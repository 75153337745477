<template>
  <div class="journey-card">
    <div v-if="isLoading" class="journey-card__loading-wrapper" data-testid="loading-wrapper">
      <LoadingCircle :size="40" />
    </div>
    <template v-else-if="journeyData">
      <BadgeLevel :level="currentStage" class="journey-card__badge" />

      <div class="journey-card__content">
        <span class="journey-card__level-label">
          Seu nível é
        </span>
        <span class="journey-card__level-name" :style="{ color: currentStageColor }" data-testid="level-name">
          {{ translatedJourneyLevel }}
        </span>
        <span class="journey-card__info" v-if="isLastJourneyStage" data-testid="max-level-message">
          Você atingiu o nível máximo da sua jornada HeroSpark.
        </span>
        <span class="journey-card__info" v-else>
          Faltam R$
          <span class="journey-card__info--highlight" data-testid="sales-amount">{{ salesToNextLevel }}</span> em vendas
          para o seu próximo nível.
        </span>
      </div>

      <ProgressCircle :journey-stage="currentStage" :progress="journeyProgressPercentage" />
    </template>
    <div v-else data-testid="error-message">
      Erro ao carregar a sua jornada.
    </div>
  </div>
</template>

<script>
import BadgeLevel from './BadgeLevel.vue';
import LoadingCircle from '@/components/LoadingCircle.vue';
import ProgressCircle from './ProgressCircle';
import { getJourneyData } from '@/services/jarvis';
import GeneralHelper from '@/shared/helpers/general';

const JOURNEY_TRANSLATE_STAGES = {
  brave: 'Brave',
  hero: 'Hero',
  super_hero: 'Super Hero',
  black: 'Black',
  golden: 'Golden',
  keep_working: 'Spark',
};

const JOURNEY_LEVEL_COLORS = {
  brave: '#F15C24',
  hero: '#08A203',
  super_hero: '#005CD6',
  black: '#474747',
  golden: '#DAB500',
  keep_working: '#262626',
};

const LAST_JOURNEY_STAGE = 'golden';

export default {
  name: 'JourneyCard',
  components: {
    BadgeLevel,
    ProgressCircle,
    LoadingCircle,
  },
  data() {
    return {
      journeyData: null,
      isLoading: true,
    };
  },
  computed: {
    isLastJourneyStage() {
      return this.journeyData?.currentStage === LAST_JOURNEY_STAGE;
    },
    currentStage() {
      return this.journeyData?.currentStage;
    },
    currentStageColor() {
      return JOURNEY_LEVEL_COLORS[this.currentStage];
    },
    salesToNextLevel() {
      if (!this.journeyData || this.isLastJourneyStage) {
        return '0';
      }
      return this.formatCurrency(this.journeyData.diffNextLevel);
    },
    translatedJourneyLevel() {
      if (!this.journeyData) {
        return JOURNEY_TRANSLATE_STAGES.keep_working;
      }
      return JOURNEY_TRANSLATE_STAGES[this.journeyData.currentStage] || JOURNEY_TRANSLATE_STAGES.keep_working;
    },
    journeyProgressPercentage() {
      if (!this.journeyData) {
        return 0;
      }

      if (this.isLastJourneyStage) {
        return 100;
      }

      const { netValue: currentSales, diffNextLevel } = this.journeyData;
      const currentLevelSales = currentSales + diffNextLevel;
      return Math.floor((currentSales / currentLevelSales) * 100);
    },
  },
  methods: {
    formatCurrency(value) {
      return value ? GeneralHelper.currency(value, false) : '0,00';
    },
  },
  async created() {
    try {
      const { data } = await getJourneyData();
      this.journeyData = data;
    } catch (error) {
      this.journeyData = null;
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.journey-card {
  display: flex;
  flex-direction: row;
  border: 1px solid #929292;
  border-radius: 4px;
  padding: 16px;

  &__loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &__badge {
    align-self: flex-start;
  }

  &__content {
    flex: 1;
    margin-left: 8px;
    margin-right: 12px;
  }

  &__level-label {
    color: #262626;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    display: block;
  }

  &__level-name {
    color: #262626;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;
    display: block;
  }

  &__info {
    color: #262626;
    font-size: 12px;
    line-height: 21px;
    display: block;

    &--highlight {
      font-weight: 700;
    }
  }
}
</style>
