





















































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class HomeTips extends Vue {
  activatedItemIndex: number = 0;
  items: Array<any> = [];

  created() {
    this.setItems();
  }

  setItems() {
    this.items = [
      {
        title: this.$t('home-page.tips.tip1.title'),
        content: this.$t('home-page.tips.tip1.content'),
        cta: {
          name: this.$t('home-page.tips.tip1.cta'),
          to: 'https://herospark.com/blog/landing-page',
        },
      },
      {
        title: this.$t('home-page.tips.tip2.title'),
        content: this.$t('home-page.tips.tip2.content'),
        cta: {
          name: this.$t('home-page.tips.tip2.cta'),
          to: 'https://herospark.com/blog/email-marketing/',
        },
      },
      {
        title: this.$t('home-page.tips.tip3.title'),
        content: this.$t('home-page.tips.tip3.content'),
        cta: {
          name: this.$t('home-page.tips.tip3.cta'),
          to: 'https://herospark.com/blog/lancamento-de-produto/',
        },
      },
    ];
  }

  activeItem(index: number) {
    const lastItemIndex = this.items.length - 1;

    if (index < 0) index = lastItemIndex;
    else if (index > lastItemIndex) index = 0;

    this.activatedItemIndex = index;
  }
}
