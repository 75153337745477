<template>
  <div class="card-dashboard">
    <div class="card-dashboard__left" v-if="!isLoading">
      <div class="card-dashboard-icon">
        <i :class="`fal fa-${icon.name} ${icon.color}`" />
      </div>

      <div class="card-dashboard-content">
        <p>{{ title }}</p>
        <strong>{{ value }}</strong>
        <a class="tlw-font-bold" @click="handleLinkNavigation">{{ routeTitle }}</a>
      </div>
    </div>

    <div class="card-dashboard__left" v-else>
      <div class="card-dashboard-icon">
        <hs-placeholder animated width="56px" height="56px" />
      </div>

      <div class="card-dashboard-content">
        <hs-placeholder animated width="154px" height="20px" class="mb-1" />
        <hs-placeholder animated width="154px" height="40px" class="mb-1" />
        <hs-placeholder animated width="154px" height="18px" />
      </div>
    </div>

    <div class="card-dashboard__right" v-if="tooltip">
      <hs-icon variant="light" icon="info-circle" v-if="!isLoading" v-b-tooltip.hover.top="tooltip" />
      <hs-placeholder animated width="24px" height="24px" v-else />
    </div>
  </div>
</template>

<script>
import GeneralHelper from '@/shared/helpers/general';
import { analyticsService } from '@/services';

export default {
  name: 'CardDashboardInfo',
  props: {
    title: {
      type: String,
      default: '',
    },
    routeTitle: {
      type: String,
      default: '',
    },
    routeName: {
      type: String,
      default: '',
    },
    icon: {
      type: Object,
      default: () => {},
    },
    value: {
      type: String,
      default: '0',
    },
    tooltip: {
      type: String,
      default: '',
    },
    event: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleLinkNavigation() {
      if (this.event)
        analyticsService.track({
          event: this.event.name,
          props: { ...this.event.props },
        });

      this.$router.push({ name: this.routeName });
    },
  },
  computed: {
    formattedNetValue() {
      if (this.netValue === 0) return 'R$ 0,00';
      return GeneralHelper.currency(this.netValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.card-dashboard {
  display: flex;
  justify-content: space-between;
  background-color: $white;
  padding: 24px;
  border-radius: 4px;

  &__right {
    color: #418cfc;
  }

  &__left {
    display: flex;
    align-self: start;
    gap: 12px;
  }

  &-icon {
    i {
      height: 56px;
      width: 56px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;

      &.green {
        color: #112b11;
        background-color: #e3fab8;
      }

      &.purple {
        color: #7427f1;
        background-color: #ead3fe;
      }

      &.blue {
        color: #2050b5;
        background-color: #b3dafe;
      }
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      font-size: 14px;
      margin: 0;
      line-height: 145%;
      color: #3f3f3f;
    }

    strong {
      font-size: 24px;
      line-height: 145%;
      color: #3f3f3f;
    }

    a {
      cursor: pointer;
      line-height: 125%;
      font-size: 14px;
      color: #4213ad;
      text-decoration: underline;
    }
  }
}
</style>
