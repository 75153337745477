
































import { Component, Vue } from 'vue-property-decorator';
import TrackingHelper from '@/shared/helpers/tracking';

@Component({
  components: {},
})
export default class HomeProductCreate extends Vue {
  location = 'home_page';
  offer_name = 'Impulso_Digital';
  email: string = this.$store.state.school.selectedSchool.owner.email;
  school_id: any = this.$store.state.school.selectedSchool.id;

  trackBannerLinkClicked() {
    TrackingHelper.trackBannerLinkClicked(this.location, this.offer_name, this.email, this.school_id);
  }
  mounted() {
    TrackingHelper.trackBannerViewed(this.location, this.offer_name, this.email, this.school_id);
  }
}
