

























import { Component, Vue } from 'vue-property-decorator';
import TrackingHelper from '@/shared/helpers/tracking';

@Component({
  components: {},
})
export default class HomeProductCreate extends Vue {
  get canCreateProduct() {
    return this.$ACL.canCreateProduct();
  }

  trackProductCreating() {
    TrackingHelper.trackProductCreationInitiated('home_page');
  }
}
