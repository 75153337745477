<template>
  <img :src="badgeAsset" class="badge-level" />
</template>

<script>
const JOURNEY_BADGES_ASSETS = {
  brave: require('@/assets/images/journey-badges/brave.png'),
  hero: require('@/assets/images/journey-badges/hero.png'),
  super_hero: require('@/assets/images/journey-badges/super.png'),
  black: require('@/assets/images/journey-badges/black.png'),
  golden: require('@/assets/images/journey-badges/golden.png'),
  keep_working: require('@/assets/images/journey-badges/spark.png'),
};

export default {
  name: 'BadgeLevel',
  props: {
    level: {
      type: String,
      required: true,
    },
  },
  computed: {
    badgeAsset() {
      return JOURNEY_BADGES_ASSETS[this.level] || JOURNEY_BADGES_ASSETS.keep_working;
    },
  },
};
</script>

<style lang="scss" scoped>
.badge-level {
  width: 48px;
  object-fit: contain;
}
</style>
