<template>
  <b-modal id="home-promotional-modal" hideFooter size="lg" headerClass="pb-0 border-0" centered>
    <hs-loading v-if="isLoading" />
    <div id="hubspotPromotionalForm" v-once></div>
  </b-modal>
</template>

<script>
import hubspotService from '@/services/hubspot';
import { hsLoading } from '@/components';

export default {
  name: 'HomePromotionalModal',
  components: {
    hsLoading,
  },
  data() {
    return {
      localHub: null,
      localJquery: null,
      isLoading: true,
    };
  },
  created() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'home-promotional-modal') {
        const scripts = hubspotService.mountEmbededHubScript();
        this.localHub = scripts[0];
        this.localJquery = scripts[1];
        this.isLoading = scripts[2];
      }
    });
  },
  beforeDestroy() {
    this.localJquery.parentElement.removeChild(this.localJquery);
    this.localHub.parentElement.removeChild(this.localHub);
  },
};
</script>
