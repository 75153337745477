


























































import axiosXavier from '@/services/axios';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import HomeBanner from './HomeBanner/HomeBanner.vue';
import HomePromotionalBanner from './HomePromotionalBanner/HomePromotionalBanner.vue';
import HomePhoneValidation from './HomePhoneValidation/PhoneValidationBanner.vue';
import ImpulsoDigitalVertical from './HomeBannerOfert/ImpulsoDigitalVertical.vue';
import ImpulsoDigitalHorizontal from './HomeBannerOfert/ImpulsoDigitalHorizontal.vue';
import HomeFunnelCreate from './HomeFunnelCreate/HomeFunnelCreate.vue';
import HomeGreetings from './HomeGreetings/HomeGreetings.vue';
import HomeProductCreate from './HomeProductCreate/HomeProductCreate.vue';
import HomeTips from './HomeTips/HomeTips.vue';
import HomeLiveMeets from './HomeLiveMeets/HomeLiveMeets.vue';
import HolidayWithdrawModal from '@/components/HolidayWithdrawModal.vue'
import CookieHelper from '@/shared/helpers/cookie.js';
import ToastHelper from '@/shared/helpers/toast';
import { liveMeetService } from '@/services';
import _ from 'lodash';
import dayjs from 'dayjs';
import CardsDashboard from './HomeBannersInfo/CardsDashboard.vue'

import satismeterMixin from './satismeterMixin';

const HomeModule = namespace('home');
const SchoolModule = namespace('school');
const PlanModule = namespace('plan');
const AuthModule = namespace('auth');

enum SellStatus {
  COMPLETE = 'complete',
  INCOMPLETE = 'incomplete',
}

enum CustomerKind {
  PRODUCER = 'producer',
  AFFILIATE = 'affiliate',
  MARKETING = 'mktProfessional',
}

@Component({
  components: {
    HomeBanner,
    HomePhoneValidation,
    HomeFunnelCreate,
    HomeGreetings,
    HomeProductCreate,
    HomeTips,
    HomeLiveMeets,
    ImpulsoDigitalHorizontal,
    ImpulsoDigitalVertical,
    HomePromotionalBanner,
    HolidayWithdrawModal,
    CardsDashboard
  },

  mixins: [satismeterMixin]
})
export default class HomePage extends Vue {
  @SchoolModule.State selectedSchool!: any;
  @SchoolModule.Action getSchoolById!: (id: number) => any;
  @HomeModule.State active_leads_count!: number;
  @HomeModule.State active_students_count!: number;
  @HomeModule.State team_size!: string;
  @HomeModule.Action requestHomeInfo!: () => any;
  @PlanModule.State actualPlan!: any;
  @AuthModule.State loggedUser!: any;

  showHome: boolean = false;
  homeStatus: any = [];
  liveMeets: any = [];
  loading: boolean = true;
  prefixLang: string = 'home-page.live-meets';
  hideBannersTemporarily: boolean = false;
  showHomeHolidayWithdrawModal: boolean = false;

  get hasMemberArea(): boolean {
    return this.$FCL.hasMemberArea();
  }

  get shouldRenderSidebar(): boolean {
    return this.hasMemberArea && (
      this.liveMeetBannerVisible ||
      this.displayImpulsoBannerVertical
    );
  }

  get displayLivesMain(): boolean {
    return this.liveMeetBannerVisible;
  }

  get displayProductCreateBanner(): boolean {
    return !this.displayLivesMain && this.hideBannersTemporarily;
  }

  get hasHolidayMeetModalCookie(): boolean {
    return CookieHelper.getCookie(`herocookie=end_year_withdraw-${this.selectedSchool.id}`)
  }

  get plataformsUsed(): boolean {
    return this.selectedSchool.extra_settings?.signup_form?.platformUsed;
  }

  get shouldNotDisplayAfterDate(): boolean {
    return dayjs().isBefore('2025-01-02');
  }
  get shouldDisplayHolidayWithdrawModal(): boolean {
    return !this.hasHolidayMeetModalCookie && this.shouldNotDisplayAfterDate;
  }

  get displayImpulsoBannerVertical(): boolean {
    return !this.liveMeetBannerVisible && this.hideBannersTemporarily;
  }

  get canLiveMeet(): boolean {
    return this.$FCL.canLiveMeet();
  }

  get liveMeetBannerVisible(): boolean {
    return this.canLiveMeet && this.liveMeets.length;
  }

  get phoneValidationComplete(): boolean {
    return !this.selectedSchool.phone_verified_at && this.$ACL.canMyAccountSettings() && false;
  }

  async getRoles() {
    this.homeStatus = await axiosXavier.get(`homepage`).then(response => response.data);
  }

  created() {
    this.notAllowRedirect();
    this.requestData();
    this.getRoles();
    this.showHolidayWithdrawModal();
    if(this.canLiveMeet) {
      this.getLives();
    }
  }

  showHolidayWithdrawModal() {
    if(this.shouldDisplayHolidayWithdrawModal) {
      this.showHomeHolidayWithdrawModal = true;
      this.showModal('holiday-withdraw');
    }
  }

  onCloseHolidayModal() {
    this.showHomeHolidayWithdrawModal = false;
    CookieHelper.setCookie(`end_year_withdraw-${this.selectedSchool.id}`)
  }

  notAllowRedirect() {
    if (!this.$FCL.canSparkHomepage()) this.$router.push({ name: 'ProductsList' });
  }

  showModal(id: string) {
    this.$bvModal.show(id);
  }

  async requestData() {
    this.requestHomeInfo();
    await this.getSchoolById(this.selectedSchool.id);
    this.showHome = true;
  }

  async getLives(page = 1) {
    try {
      this.loading = true;
      const payload = [
        { key: 'per_page', value: 20 },
        { key: 'page', value: page },
      ];

      await liveMeetService.getAll(payload).then(res => {
        const { live_meet_lessons, current_page, total_pages } = res;
        this.liveMeets = _.unionBy(
          this.liveMeets,
          live_meet_lessons.filter(live => live.status == 'available')
        );
        if (current_page < total_pages) {
          this.getLives(page + 1);
        }
      });
    } catch (error) {
      if (error && error.response?.status !== 401) ToastHelper.dangerMessage(this.$t(`${this.prefixLang}.toast.error.load`));
    } finally {
      this.loading = false;
    }
  }
}
