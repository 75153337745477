import { render, staticRenderFns } from "./HomeFunnelCreate.vue?vue&type=template&id=38f057ab&scoped=true&"
import script from "./HomeFunnelCreate.vue?vue&type=script&lang=ts&"
export * from "./HomeFunnelCreate.vue?vue&type=script&lang=ts&"
import style0 from "./HomeFunnelCreate.vue?vue&type=style&index=0&id=38f057ab&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38f057ab",
  null
  
)

export default component.exports